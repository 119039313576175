import React from 'react'
// import Footer from '../common/footer/Footer'
import About from './about_us/About'
import Carousel from './carousel/Carousel'
import Counter from './counter/Counter'
import NoticesEvents from './notices-events/NoticesEvents'
import Testimonial from './testimonial/Testimonial'
import Thadar from './thadar/Thadar'

const Home = () => {
  return (
    <>
        <Thadar/>
        <About/>
        <Carousel/>
        <NoticesEvents/>
        <Counter/>
        <Testimonial/>
        
    </>
  )
}

export default Home