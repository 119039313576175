export const head=[
    {
        img:"../images/team-img/t1.webp",
        name:"Ram Thapa",
        title:"Principal",
        category:"admin"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"admin"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"admin"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"admin"
    },





    {
        img:"../images/team-img/t3.webp",
        name:"thapa",
        title:"vice-principal",
        category:"house"
    },

    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"house"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"primary"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"secondary"
    },
    {
        img:"../images/team-img/t6.webp",
        name:"thapa",
        title:"vice-principal",
        category:"house"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"house"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"house"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"secondary"
    },
    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"secondary"
    },

    {
        img:"../images/team-img/t2.webp",
        name:"thapa",
        title:"vice-principal",
        category:"secondary"
    },

]