import React from 'react'
import "./about.css"
// import school from "../../../images/school.jpg"
// import principal from "../../../images/principal.jpg"

const About = () => {
  return (
    <>
    <div className='about'>
        <div data-aos="fade-right" className='about-school flexSB'>
            <div className='img'>
                <img src="../images/school.jpg" alt="school"/>
            </div>
            <div data-aos="fade-right" data-aos-duration="2000" className='school-description'>
                <h1>CEBS</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Reiciendis illo tempora obcaecati, doloremque recusandae consectetur minus placeat voluptatibus odit. Veniam iure molestias, tenetur aliquam aspernatur accusamus nulla eveniet ex officia explicabo ducimus amet laborum aperiam minima veritatis numquam eum quidem, et labore. Inventore reiciendis unde </p>
                <button className='primary-btn'>Read More</button>
            </div>
        </div>
        <div data-aos="fade-left" data-aos-duration="2500"className='principal-section '>
           <div className="principal-img">
            <img src="../images/principal.jpg" alt="principal"/>
           </div>
           <div className="principal-word">
            <h1>Devendara Thapa</h1>
            <h3>Principal</h3>
            <h4>"Lorem ipsum dolor sit amet consectetur"</h4>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, itaque! Voluptatem veritatis nesciunt explicabo rerum non ratione voluptas maxime inventore.</p>
            <button className='primary-btn'>Read More</button>
           </div>
        </div>
        {/* <div style={{marginBottom:"30rem"}}></div> */}
    </div>
    <div style={{marginBottom:"31.5rem"}}></div>
    </>
  )
}

export default About