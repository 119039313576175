export const introduction=[
    {
     title:"Introduction",
     desc: <div><p style={{margin: "10px 0"}}>A school is an educational institution designed to provide learning spaces and learning environments for the teaching of students under the direction of teachers. Most countries have systems of have completed primary education. An institution where higher education is taudents progress through a series of schools. Theeeeeeeeeeeeee names for these schools vary by country (discussed in the Regional terms section below) but generally include primary school fught is commonly called a university college or universi</p><p>A school is an educational institution designed to provide learning spacesing of students unh is sometimes compulsory.[2] In these systems, students progress through a series of schools. Theeeeeeeeeeeeee names for these schools vary by country (discussed in the Regional terms  education. An institution where higher education</p></div>
    
    }]

    export const message=[

    {
        title:"Message From The Head",
        img:"../images/principal.jpg",
        desc:"direction of teachers. Most countries have systems of formal education, which is sometimes compulsory.[2] In these systems, students progress through a series of schools. The names for these schools vary by country (discussed in the Regional terms section below) but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught is commonly called a university college or university."
    },]

    export const mission=[

    {
        title:"Mission",
        desc:"direction of teachers. Most countries have systems of formal education, which is sometimes compulsory.[2] In these systems, students progress through a series of schools. The names for these schools vary by country (discussed in the Regional terms section below) but generally include primary school for young children and secondary school for teenagers who have completed primary education. An institution where higher education is taught is commonly called a university college or university."   

    },]

    export const objectives=[

    {
        title:"Objectives",
        desc:"",
        p1: "To provide quality education to aspiring students.",
        p2: "To provide quality education to aspiring students.",
        p3: "To provide quality education to aspiring students.",
        p4: "To provide quality education to aspiring students.",
        p5: "To provide quality education to aspiring students.",
        p6: "To provide quality education to aspiring students.",

            
           

    }
]