// import { ArrowRightAlt } from '@mui/icons-material'
import React from 'react'
import "./thadar.css"
import Typewriter from "typewriter-effect"

const Thadar = () => {
  return (
    <div>
      <section className='thadar container'>
        
          <div className='thadar-img'>

          </div>
          <div data-aos="zoom-out-down" className='thadar-text'>
            <h2>Welcome to CEBS</h2>
            <h1>
              <Typewriter 
              options={{
                loop:true,
                delay:75,
              }}
              onInit={(typewriter)=>{
                typewriter.typeString("School of Commitment")
                .pauseFor(1500)
                .deleteChars(10)
                typewriter.typeString("Love and Care")
                .pauseFor(1500)
                .deleteChars(13)
                typewriter.typeString("Excellence!")
                .pauseFor(10000)
                .start();
              }}
            //   options={{strings:["School Of Excellence"],
            //   deleteChars:(10),
            //   autoStart:true,
            // loop:true,}}
            />
            </h1>
            <p>A learning community dedicated to building respectful and responsible citizens and empowering all learners.</p>
            <div data-aos="fade-up">
            <button>
              Learn More<i class="fa-solid fa-arrow-right-long"></i>
            </button>
            <button className='primary-btn'>
              Contact Us<i class="fa-solid fa-arrow-right-long"></i>
            </button>
            </div>
          </div>
        
      </section>
      <div style={{marginBottom:"15rem"}}></div>

    </div>
  )
}

export default Thadar