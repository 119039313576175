import React from 'react'
import { introduction, message, mission, objectives } from '../../data/about-d/aboutD'
import Back from '../common/back/Back'
import "./about.css"

const About = () => {
  return (
    <div>
      <Back title="About Us"/>
      
      <div className='margin'>

      </div>
      <div className='aboutSec'>
        <div className='f'>
          <div className='about-i '>
            {introduction.map((val, i) => (
              <div>
                <h1  >{val.title}</h1>
                <p data-aos="fade-right">{val.desc}</p>
              </div>
            ))}
          </div>
          <div className='about-i messageSec'>
            {message.map((val, i) => (
              <div>
                <h1 >{val.title}</h1>
                <img src={val.img} alt="" />
                <div className='head-m'>
                  <h3>Ram Thapa</h3>
                  <span>Principal</span>
                </div>
                <p data-aos="fade-right">{val.desc}</p>
              </div>
            ))}
          </div>
        </div>
        <div className='s'>
        <div className='about-i'>
          {mission.map((val, i) => (
            <div>
              <h1 >{val.title}</h1>
              <p data-aos="fade-right">{val.desc}</p>
            </div>
          ))}
        </div>
        <div className='about-i objeSec'>
          {objectives.map((val, i) => (
            <div>
              <h1 >{val.title}</h1>
              <p data-aos="fade-right">{val.desc}</p>
              <ul data-aos="fade-right">
                <li><i class="fa-solid fa-circle"></i>{val.p1}</li>
                <li><i class="fa-solid fa-circle"></i>{val.p2}</li>
                <li><i class="fa-solid fa-circle"></i>{val.p3}</li>
                <li><i class="fa-solid fa-circle"></i>{val.p4}</li>
                <li><i class="fa-solid fa-circle"></i>{val.p5}</li>
              </ul>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  )
}

export default About