export const noticesD=[
    {
        id:1,
        date:"16th August, 2022",
        title:"First Terminal Examination",
        desc:"hello in the world hello in the world hello in the world"
    },

    {
        id:2,
        date:"16th August, 2022",
        title:"First Terminal Examination",
        desc:"hello in the world hello in the world hello in the world"
    },

    {
        id:3,
        date:"16th August, 2022",
        title:"First Terminal Examination",
        desc:"hello in the world hello in the world hello in the world"
    },

    {
        id:4,
        date:"16th August, 2022",
        title:"First Terminal Examination",
        desc:"hello in the world hello in the world hello in the world"
    },




]