import React, { useState } from 'react'
import Head from './Head'
import { Link } from 'react-router-dom'
// import CEBS from "../../../images/CEBS.png"

const Header = () => {

  const [mobile, setMobile] = useState(false);



  return (
    <>
      <Head />
      <div className='how'>

        <div id="navBar" className='header'>

          <div className='nav-link flexSB '>
            <div
              className="logo flexSB"
            >
              <img src="../images/CEBS.png" alt="logo" />
              <div className='logo-title'>
                <h2>Chakreshwor English</h2>
                <h2> Boarding School</h2>
                <h5>Established 2060 BS</h5>
              </div>
            </div>
            <div className=' nav  '>
              <ul className={mobile ? "nav-ul-mobile" : 'nav-ul'}
              // onClick={()=> setMobile(false)}
              >
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/management-team">Team</Link>
                </li>
                <li>
                  <a href="#notice">Notices
                  </a>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>

              </ul>


              <button className="menu-btn" onClick={() => setMobile(!mobile)}>
                {mobile ?<i class="fa-solid fa-xmark"> </i> : <i class="fa-solid fa-bars"></i>}
                </button>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}




window.addEventListener('scroll', () => {
  document.querySelector(".header").classList.toggle("window-scroll", window.scrollY > 0);
  document.querySelector(".logo").classList.toggle("logo-new", window.scrollY > 5);
})



export default Header





  // const [state, setState]= useState(false);

  // const changeClass=()=>{
  //   const scrollValue = document.documentElement.scrollTop;
  //   if (scrollValue>100){
  //     setState(true);
  //   }

  //   else{
  //     setState(false);
  //   }
  // }
//   const navLink = document.querySelector(".nav-ul");
// const menuBtn= document.querySelector('#navbar-open');
// const closeBtn=document.querySelector("#navbar-close");

// menuBtn.addEventListener("click", ()=>{
//   navLink.style.display="flex";
//   closeBtn.style.display="inline-block";
//   menuBtn.style.display="none";
// })

// const closeNav =()=>{
//   navLink.style.display="none";
//   closeBtn.style.display="none";
//   menuBtn.style.display="inline-block";
// }

// closeBtn.addEventListener("click", closeNav)

// (window).scroll(function() {
//   if ((window).scrollTop() === 0) {
//       // We are at the top of the page and want to remove the class
//       ('#topNav').removeClass('logo-new');
//   } else {
//       ('#topNav').addClass('logo-new');
//   }
// });
