import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./carousel.css";


import { Autoplay, Pagination, Navigation } from "swiper";


const Carousel = () => {
    return (
        <>
        <div  className='carousel '>
            <div className='middle-design'>
                
                <img  src="../images/carousel-img/second copy.png" alt="" />
                
                <div className='middle-design-text'>
                    <h1>An Investmen In Knowledge</h1><h1 className='mdl-text'>pays the best</h1><h1 className='lst-text'> intrest!!!</h1>
                    <h3>CEBS, Chandrakot-4, Gulmi</h3>
                </div>
            </div>
            <div className='carousel-design'>
                <Swiper
                    slidesPerView={3}
                    spaceBetween={30}
                    slidesPerGroup={3}
                    loop={true}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                        <div className='carousel-img'>
                            <img src="../images/carousel-img/b1.webp" alt="" />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide><div className='carousel-img'>
                        <img src="../images/carousel-img/b1.webp" alt="" />
                    </div></SwiperSlide>
                    <SwiperSlide><div className='carousel-img'>
                        <img src="../images/carousel-img/b1.webp" alt="" />
                    </div></SwiperSlide>
                    <SwiperSlide><div className='carousel-img'>
                        <img src="../images/carousel-img/b1.webp" alt="" />
                    </div></SwiperSlide>
                    <SwiperSlide><div className='carousel-img'>
                        <img src="../images/carousel-img/b1.webp" alt="" />
                    </div></SwiperSlide>
                    <SwiperSlide><div className='carousel-img'>
                        <img src="../images/carousel-img/b1.webp" alt="" />
                    </div></SwiperSlide>
                    <SwiperSlide><div className='carousel-img'>
                        <img src="../images/carousel-img/b1.webp" alt="" />
                    </div></SwiperSlide>
                    <SwiperSlide><div className='carousel-img'>
                        <img src="../images/carousel-img/b1.webp" alt="" />
                    </div></SwiperSlide>
                    <SwiperSlide><div className='carousel-img'>
                        <img src="../images/carousel-img/b1.webp" alt="" />
                    </div></SwiperSlide>
                </Swiper>

            </div>
        </div>
        {/* <div style={{marginBottom:"30rem"}}></div> */}
        </>
    )
}

export default Carousel