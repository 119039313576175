import React from 'react'
import { TestimonialD } from '../../../data/testimonial-d/testimonial'
import "./testimonial.css"

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination } from "swiper";


const Testimonial = () => {
  return (
    <div className='testimonial'>
      <h1  data-aos="zoom-out" className='test-head'>Testimonial</h1>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={false}
        slidesPerView={"3"}
        coverflowEffect={{
          rotate: 100,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
         <div  className='test-first'>
          <h1 className='right'>Testimonilas To The Right</h1>
          <img  className="right-img "src="../images/testimonial-img/right.png" alt=""/>
          <h1 className='swipe'>Swipe Left</h1>
          <img className='swipe-img' src="../images/testimonial-img/swipe-left-icon.png" alt=""/>
         </div>
        </SwiperSlide>
        
      {TestimonialD.map((val,i)=>(
        <SwiperSlide>
        <div  className='con'>
          <div className="test-up">
          <div >
          <img src={val.image} alt=""/>
          </div>
          <div className="test-text">
          <h1 zoom-out-left>{val.name}</h1>
          <h3>{val.title}</h3>
          </div>
          </div>
          <i class="fa-sharp fa-solid fa-quote-left"></i>
          <p style={{color:"unset"}}>{val.statement}</p>
          
          </div>
          </SwiperSlide>
      ))}

      <SwiperSlide>

      </SwiperSlide>
      
      </Swiper>
    </div>
  )
}

export default Testimonial