export const EventsD=[
    {
        day:"16",
        month:"August",
        title:"Cricket Tournament",
        desc:"hello from the other world. hello from the other world. hello from the other world. hello from the other world. hello from the other world. hello from the other world."


    },

    {
        day:"16",
        month:"Jun",
        title:"Cricket Tournament",
        desc:"hello from the other world. hello from the other world. hello from the other world. hello from the other world. hello from the other world. hello from the other world."


    },

    {
        day:"06",
        month:"August",
        title:"Cricket Tournament",
        desc:"hello from the other world. hello from the other world. hello from the other world. hello from the other world. hello from the other world. hello from the other world."


    },

    {
        day:"16",
        month:"september",
        title:"Cricket Tournament",
        desc:"hello from the other world."


    },
]