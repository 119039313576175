import React from 'react'
import Back from '../common/back/Back'
import "./contact.css"

const Contact = () => {

  const map="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1759.5467726147501!2d83.413684!3d28.113180000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4dfbb7e5df5f3fcf!2sChakreshwor%20English%20Boarding%20School!5e0!3m2!1sen!2snp!4v1665406778781!5m2!1sen!2snp"
  
  return (
        <div >
          <Back title="Contact"/>
      <div className='contact-container flexSB'>
      <div className='contact-mapSec'>
        <div className='mapSec-list'>
         <ul>
          <li> <i class="fa-sharp fa-solid fa-map-location-dot"></i> Chandrakot-4, Shantipur, Gulmi</li>
          <li><i class="fa-sharp fa-solid fa-phone"></i>+988438489394, +079 420054</li>
          <li><i class="fa-solid fa-envelope"></i>cebs@gmail.com</li>
         </ul>
         </div>
         <div className='con-sec'>
          <h3>Follow Us On</h3>
          <i class="fa-brands fa-square-facebook"></i>
          <i class="fa-brands fa-square-twitter"></i>
          <i class="fa-brands fa-square-instagram"></i>

         </div>
          <div>
            <iframe src={map}></iframe>
          </div>
      </div>
          <div className='contact-form'>
           <div className='flexSB'>
            <input type="text" placeholder="First Name"/>
            <input type="text" placeholder="Last Name"/>
           </div>
           <input type="email" placeholder="Email"/>
           <input type="text" placeholder="Subject"/>
           <textarea cols="30" rows="10">Your Message Here...</textarea>
           <button className="primary-btn">SUBMIT</button>
          </div>

  
    </div>
    </div>
  )
}

export default Contact