import React from 'react'
import "./header.css"
import {LocationOn, Email, Phone, WhatsApp,YouTube,Facebook} from '@mui/icons-material';

const Head = () => {
  return (
    <div>
      <div className='head-full'>
        <section className='head '>
            <div className='head-left flexSB'>
            <i class="fa-solid fa-location-dot"></i>
              <h3>Chandrakot-4, Gulmi</h3>
              <i class="fa-solid fa-envelope"></i>
              <h3>cebs@gmail.com</h3>
              <i class="fa-sharp fa-solid fa-phone"></i>
              <h3>079-420001, 9800000448</h3>
            </div>
            <div className='head-right flexSB'>
                <i><Facebook fontSize='large'/></i>
                <i><WhatsApp fontSize='large'/></i>
                <i><YouTube fontSize='large'/></i>
            </div>

        </section>
        </div>
    </div>
  )
}

export default Head