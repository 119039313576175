export const TestimonialD=[
    {
        image:"../images/testimonial-img/b1.webp",
        name: "Pawan Thapa",
        title: "Student",
        statement:"Hello from the other side. Hello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other side"
    },

    {
        image:"../images/testimonial-img/b2.webp",
        name: "Pawan Thapa",
        title: "Bottle",
        statement:"Hello from the other side. Hello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other side"
    },

    {
        image:"../images/testimonial-img/b3.webp",
        name: "Pawan Thapa",
        title: "Cow",
        statement:"Hello from the other side. Hello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other side"
    },

    {
        image:"../images/testimonial-img/b4.webp",
        name: "Pawan Thapa",
        title: "Diamond",
        statement:"Hello from the other side. Hello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other side"
    },

    {
        image:"../images/testimonial-img/b5.webp",
        name: "Pawan Thapa",
        title: "Shridi",
        statement:"Hello from the other side. Hello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other sideHello from the other side"
    },

    {
        image:"../images/testimonial-img/b6.webp",
        name: "Pawan Thapa",
        title: "Flame",
        statement:"hello in the world"
    },


]