import React from 'react'
import { EmojiEvents, MilitaryTech } from '@mui/icons-material';
import CountUp from 'react-countup';

import "./counter.css"

const Counter = () => {
  return (
    <div className='counter container'>


      <div data-aos="zoom-in" className='counter-b flex' >
        <div className='counter-i'>
        <i><EmojiEvents/></i>
        <h1>
        <CountUp enableScrollSpy duration={3} end={20}/>+
        </h1>
        <h4>Mentors</h4>
        </div>
        <div className='v-line'></div>
        <div className='counter-i'>
        <i><MilitaryTech/></i>
        
        <h1>
        <CountUp enableScrollSpy duration={3} end={600}/>+</h1>
        <h4>Students</h4>
        </div>
        <div className='v-line'></div>
        <div className='counter-i'>
        <i><EmojiEvents/></i>
        <h1>
        <CountUp enableScrollSpy duration={3} end={50}/>+</h1>
        <h4>Students Organizations</h4>
        </div>
        <div className='v-line'></div>
        <div className='counter-i'>
        <i><MilitaryTech/></i>
        <h1>
        <CountUp enableScrollSpy duration={3} end={20}/>+</h1>
        <h4>Students</h4>
        </div>

      </div>
      


    </div>
  )
}

export default Counter