import React from 'react'
import { EventsD } from '../../../data/events-d/EventsD'
import { noticesD } from '../../../data/notices-d/noticesD'
import "./noticesEvents.css"

const NoticesEvents = () => {
  return (
    <>
    <div  id="notice" className='noticesEvents'>
    <div  className='events'>

      <h1 className='events-head'>Latest Events</h1>
      <div  className='events-wrap'>
      {EventsD.map((val,i)=>(
        <div className='events-con'>
         
          <div className='calender'>
          <i class="fa-regular fa-calendar"></i>
            <h1>{val.day}</h1>
            <h3>{val.month}</h3>
            </div>
            <div>
              <h2>{val.title}</h2>
              <p>{
              val.desc.length>60? val.desc.slice(0,60)+"...":val.desc}</p>
              </div>
        </div>
      ))}
      </div>
      
    </div>
    <div className='notices'>
      <h1 className='notices-head'>Notices</h1>
      <div className='notice-wrap'>
      {noticesD.map((val,i)=>(
        <div className='notice-con'>
          <h3>{val.date}</h3>
          <h2>{val.title}</h2>
          <p>{
          val.desc.length>20? val.desc.slice(0,20)+"...": val.desc}</p>
          
          </div>
      ))}
      </div>
      

    </div>
    </div>
    </>
  )
}

export default NoticesEvents