import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import "./App.css"
import About from './components/about/About';
import Header from './components/common/header/Header';
import Home from './components/home/Home';
import Footer from "./components/common/footer/Footer"
import Team from './components/management-team/Team';
import Contact from './components/contact/Contact';
import AOS from "aos";
import "aos/dist/aos.css"


const App = () => {

  useEffect(()=>{
    AOS.init()
    AOS.refresh()

  },[])

  return (
    <>
    <Router>
    <div>
      <Header/>
    <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/about">
            <About/>
          </Route>
          <Route exact path="/management-team"><Team/></Route>
          <Route exact path="/contact"><Contact/></Route>
          
        </Switch>
    </div>
    {/* <Footer/> */}
    </Router>
    </>
  )
}



export default App