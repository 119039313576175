import React, { useState } from 'react'
import { all, head, sub } from '../../data/teams-d/teamsD'
import Back from '../common/back/Back';
import "./team.css"

const Team = () => {

const [list, setList]= useState(head);

const filterItem=(currentItem)=>{
  const newItem= head.filter((yitem)=>{
    return yitem.category=== currentItem;
  })

  setList(newItem);

  if (currentItem=== "all"){
    setList(head)
  }
}
  return (
    <div>
      <Back title="Management-Team"/>
      <div className='teamSec'>
        <h1>Meet Our Team</h1>
        <div className='team-btn'>
        <button className='' onClick={()=>filterItem("all")}>All</button>
          <button className='' onClick={()=>filterItem("admin")}>Admin</button>
          <button className='' onClick={()=>filterItem("house")}>House</button>
          <button className='' onClick={()=>filterItem("secondary")}>Secondary</button>
          <button className='' onClick={()=>filterItem("primary")}>Primary</button>
          </div> 
        <div   className="t-first">
        {list.map((val,i)=>(
          <div data-aos="flip-left" className='team t1' >
            <img src={val.img} alt=""/>
            <h3>{val.name}</h3>
            <p>{val.title}</p>
            <div className='team-socials'>
              <a href="#">
            <i class="fa-brands fa-facebook-messenger"></i></a>
            <a href="#">
            <i class="fa-brands fa-whatsapp"></i></a>
            <a href="#">
            <i class="fa-solid fa-envelope"></i></a>
            </div>
          </div>
        ))}
        </div>
        
        
      </div>
    </div>
  )
}

export default Team