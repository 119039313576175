import React from 'react'
import { Link } from 'react-router-dom'
import "./footer.css"
// import {PlayArrow} from '@mui/icons-material';

// var date = new Date();
var year= new Date().getFullYear();


const Footer = () => {
  return (
    <div>
      <footer>
        <div className='footer'>
          <div className='foot first'>
           <h1>CEBS</h1>
           <span>Love & Service</span>

           <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Itaque natus, odit cupiditate officia neque sit esse vel ipsum repellat sunt.</p>
           
          </div>
           <div className='foot second'>
              <h3>Quick Links</h3>
              <ul>
                <li>
                <i class="fa-solid fa-play"></i>
                  <Link to="/about">About</Link>
                </li>
                <li>
                <i class="fa-solid fa-play"></i>
                  <Link to="/team">Team</Link>
                </li>
                <li>
                <i class="fa-solid fa-play"></i>
                  <Link to="/notice">Notice</Link>
                </li>
                <li>
                <i class="fa-solid fa-play"></i>
                  <Link to="/contact">Contact</Link>
                </li>
                <li>
                <i class="fa-solid fa-play"></i>
                  <Link to="/">About</Link>
                </li>
              </ul>
           </div>
           <div className='foot third'>
                <h3>Have a questtion?</h3>
                <ul>
               
                  <li> <i class="fa-solid fa-location-dot"></i>
                  Chandrakot-4,Gulmi</li>
                  
                  <li>
                  <i class="fa-solid fa-phone"></i>+079-420000, +977-980000000</li>
                 
                  <li>
                  <i class="fa-solid fa-envelope"></i>cebs@gmail.com</li>
                  
                  <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-instagram icon'></i>
                </ul>
           </div>

       

        </div>
        <div className='copyright'>
           <span className='span-f'>Copyright © {year} All rights reserved CEBS</span>
           <span className='span-s'>Pawan Thapa(KF)</span>
        </div>
      </footer>
    </div>
  )
}

export default Footer